.wrapper {
  width: 100%;
  height: 95vh;
  max-width: 1400px;
  text-align: center;
  margin: 0 auto;
}

.unity-container {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
}

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(51, 48, 48);
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  width: 100px;
  height: 10px;
  background-color: rgb(99, 99, 99);
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 10px;
  background-color: rgb(190, 190, 190);
  transition: width 0.5s ease;
}

.unity-canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

/* .btn_expand , .btn_exit {
  position: absolute;
  z-index: 1000;
  right: 5px;
  top: 5px;
  background: transparent;
  border-radius: 5px;
  padding: 3px 8px;
} */

.header {
  height: 50px;
}

.header .logo {
  width: 153px;
  height: 32.5px;
  float: left;
  margin: 0px;
}

.header button {
  float: right;
}

.game_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game_footer .game_version p {
  color: #fff;
  font-size: 18px;
}

.logo {
  display: block;
  margin: 15vh auto 20px auto;
}

.description {
  text-align: center;
  color: #FFFFFF;
}

.btn_voice,
.btn_expand {
  height: 38px;
  width: 38px;
  padding: 8px;
  border-radius: 50%;
  background-color: #05A3FF;
  color: #efefef;
}

.header .about_btn {
  float: right;
}

.footer {
  color: #fff;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
}

.social-links {
  margin: 20px 0 30px 0;
}

.social-links a {
  font-size: 30px;
  padding: 10px;
}

.copywriter {
  font-size: 14px;
  font-weight: 400;
  color: #FFD4CB;
}