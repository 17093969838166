* {
  box-sizing: border-box;
}

html {
  --bg: #efefef;
  --fg: #1e1e1e;
  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: rgb(90, 206, 132);
  --primary: #FF585D;
  --secondary: #05A3FF  ;

  /* background-color: var(--bg); */
  background: var(--secondary);
  background: radial-gradient(circle, var(--secondary) 2%, #efefef 100%);
  color: var(--fg);
  font-family: 'SF Pro Display', sans-serif;
  font-size: calc(0.9em + 0.5vw);
  line-height: 1.3;
  letter-spacing: 1px;
}

body {
  margin: 0;
  padding: 1em;
}

h1 {
  background-image: url(assets/logo-black.svg);
  background-position: center 1em;
  background-repeat: no-repeat;
  background-size: auto 1.5em;
  margin-top: 0;
  padding: 3.5em 0 0.5em;
  text-align: center;
}

a,
.link {
  color: #fff;
  text-decoration: none;
}

a:hover,
a:focus,
.link:hover,
.link:focus {
  text-decoration: none;
}

a:active,
.link:active {
  color: var(--secondary);
}

button,
input {
  font: inherit;
  outline: none;
}

button {
  background-color: #efefef;
  border-radius: 22px;
  border: none;
  font-size: 18px;
  color: var(--secondary);
  cursor: pointer;
  padding: 10px 40px;
  transition: transform 30ms;
}

button:hover,
button:focus {
  box-shadow: 0 0 10em rgba(255, 255, 255, 0.2) inset;
}

button:active {
  box-shadow: 0 0 10em rgba(0, 0, 0, 0.1) inset;
}

button.link {
  background: none;
  border: none;
  box-shadow: none;
  display: inline;
}

[disabled] button,
button[disabled] {
  box-shadow: none;
  background-color: var(--light-gray);
  color: gray;
  cursor: not-allowed;
  transform: none;
}

[disabled] button {
  text-indent: -900em;
  width: 2em;
  position: relative;
}

[disabled] button:after {
  content: " ";
  display: block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: var(--fg) transparent var(--fg) transparent;
  animation: loader 1.2s linear infinite;
  position: absolute;
  top: 0.45em;
  right: 0.5em;
}

@keyframes loader {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

fieldset {
  border: none;
  padding: 2em 0;
}

input {
  background-color: var(--shadow);
  border: none;
  border-radius: 5px 0 0 5px;
  caret-color: var(--primary);
  color: inherit;
  padding: 0.25em 1em;
}

input::selection {
  background-color: var(--secondary);
  color: #efefef;
}

input:focus {
  box-shadow: 0 0 10em rgba(0, 0, 0, 0.02) inset;
}

code {
  color: var(--gray);
}

li {
  padding-bottom: 1em;
}

aside {
  animation: notify ease-in-out 10s;
  background-color: var(--shadow);
  border-radius: 5px;
  bottom: 0;
  font-size: 0.8em;
  margin: 1em;
  padding: 1em;
  position: fixed;
  transform: translateY(10em);
  right: 0;
}

aside footer {
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  margin-top: 0.5em;
}

aside footer *:first-child {
  color: var(--success);
}

aside footer *:last-child {
  color: var(--gray);
}

@keyframes notify {
  0% {
    transform: translateY(10em)
  }

  5% {
    transform: translateY(0)
  }

  95% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(10em)
  }
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #1e1e1e;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }

  h1 {
    background-image: url(assets/logo-white.svg);
  }

  input:focus {
    box-shadow: 0 0 10em rgba(255, 255, 255, 0.02) inset;
  }
}